import useAppContext from 'app/store/useAppContext'
import { AffirmationForm } from 'features/affirmation/form/form'
import classNames from 'shared/lib/class-names'
import { getCurrentDate } from 'shared/utils/functions'
import IdeaImg from 'shared/assets/images/contacts/IdeaImg.webp'

import cls from 'pages/home/contacts/Contacts.module.scss'

export default function Contacts() {
  const {
    store: { isMobile, isMobileSmall, user },
    dispatch,
  } = useAppContext()

  const currentDate = getCurrentDate()

  const modalTitle =
    'Оставьте свои контактные данные и начните получать пассивный доход'

  return (
    <section className={classNames('flex__row_center', cls.page)} id="contacts">
      <div className={classNames(cls.page__container, 'container__desktop')}>
        <div className={cls.left_block}>
          <h4>
            Откройте инвест-счет
            <span>
              ДО {currentDate} <span>и получите</span>
            </span>
          </h4>

          {isMobileSmall && <img src={IdeaImg} alt="IdeaImg" />}

          <h3>20 000 ₸</h3>
          <h5>К ВАШЕМУ ОСНОВНОМУ ПАКЕТУ</h5>
        </div>

        <div className={cls.right_block}>
          <AffirmationForm user={user} dispatch={dispatch} title={modalTitle} />
        </div>
      </div>
    </section>
  )
}
