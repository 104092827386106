import classNames from 'shared/lib/class-names'
import { getFutureDate } from 'shared/utils/functions'

import cls from './FirstDividends.module.scss'

export const FirstDividends = () => {
  const futureDate = getFutureDate()

  return (
    <section className={classNames('flex__col_center', cls.page)}>
      <div className={classNames(cls.page__container, 'container__desktop')}>
        <div className={cls.header}>
          <h3 className={cls.header__title}>
            Получите первые дивиденды уже через 3 месяца
          </h3>
          <p className={cls.header__desc}>
            За счет диверсификации активов Вы получаете надежный инвест-портфель
          </p>
        </div>
        <div className={cls.content}>
          <div className={cls.card}>
            <div>
              <p>Текущая средняя доходность</p>
              <h4>+65,17%</h4>
            </div>
            <div>
              <p>Прибыль</p>
              <h4>+1 180,25 $</h4>
            </div>
          </div>
          <div className={cls.card}>
            <div className={cls.card__left}>
              <div>
                <p>Прогноз прибыли через 3 месяца</p>
                <p>
                  <span>{futureDate}</span>
                </p>
              </div>

              <div>
                <p>Горизонт инвестирования</p>
                <p>
                  <span>от 3 до 6 месяцев</span>
                </p>
              </div>
            </div>
            <div className={cls.card__divider}></div>
            <div className={cls.card__right}>
              <p>Ваша доходность портфеля</p>
              <h4>+121,3%</h4>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
