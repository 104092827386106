import { sumCurrency } from 'shared/consts/sumCurrency'

type CardsContentType = {
  id: number
  title: string
  desc1: string
  desc2: string
}

export const cardsContent: CardsContentType[] = [
  {
    id: 1,
    title: '01',
    desc1: 'Регистрация',
    desc2: 'Заполните форму заявки на открытие инвест-счета',
  },
  {
    id: 2,
    title: '02',
    desc1: 'Звонок',
    desc2: 'Дождитесь звонка помощника по телефону',
  },
  {
    id: 3,
    title: '03',
    desc1: 'Старт',
    desc2: `Пополните счет на ${sumCurrency.minInvestSum} ₸ и получайте пассивный доход`,
  },
]
