import React, { useEffect, useState } from 'react'

import useAppContext from 'app/store/useAppContext'
import { setIsAffirmationModal } from 'app/store/actions'
import { AffirmationForm } from 'features/affirmation/AffirmationForm'
import { Button, IButton } from 'shared/ui/button'
import { Modal } from 'shared/ui/modal'

import styles from './SubmitModal.module.scss'

interface ISubmitAffirmationProps {
  btnProps?: IButton
  btnContent?: string
}

const customSubmitModalStyles = {
  overlay: {
    background: 'var(--bg-opacity2)',
  },
  content: {
    maxWidth: '455px',
    maxHeight: 'calc(100vh - 67px)',
    overflow: 'auto',
    boxShadow: 'var(--box-shadow)',
  },
}
const customMobileSmallModalStyles = {
  overlay: {
    backgroundColor: 'var(--bg-opacity2)',
  },
  content: {
    width: '100vw',
    height: `calc(100vh - 100px)`,
    maxHeight: '100vh',
    margin: '67px 0 0 0',
    overflow: 'auto',
  },
}

export function SubmitAffirmationModal(props: ISubmitAffirmationProps) {
  const { btnProps, btnContent } = props

  const {
    store: {
      isMobile,
      isMobileSmall,
      modals: { isAffirmationModal, isSuccessAffirmationModal },
      user,
    },
    dispatch,
  } = useAppContext()

  const [modalIsOpen, setModalIsOpen] = useState(false)

  const openModal = () => {
    dispatch(setIsAffirmationModal(true))
  }
  const closeModal = () => {
    dispatch(setIsAffirmationModal(false))
  }

  useEffect(() => {
    setModalIsOpen(isAffirmationModal)
  }, [isAffirmationModal])

  const isMobileModalOpen = isMobileSmall && isAffirmationModal
  const customStyles = isMobileModalOpen
    ? customMobileSmallModalStyles
    : customSubmitModalStyles

  const modalTitle =
    'Оставьте Ваши контакты,\n' + 'чтобы получить полный доступ'

  return (
    <div>
      <Button {...btnProps} onClick={openModal}>
        {btnContent ?? 'Открыть счет онлайн'}
      </Button>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        titleClass={styles.modal__title}
        style={customStyles}
        isCloseIcon={!isMobileModalOpen}
        content={
          <AffirmationForm
            user={user}
            dispatch={dispatch}
            className={styles.modal__form}
            title={modalTitle}
            isAffirmationModal={isAffirmationModal}
          />
        }
      />
    </div>
  )
}
