import type { BeforeMaskedStateChangeStates } from 'react-input-mask'
import { PAST_BUG_PHONE, PHONE_TITLE } from 'features/affirmation/_model/constants'

export const phoneMaskChange = ({
                                         currentState,
                                         nextState,
                                       }: BeforeMaskedStateChangeStates) => {
  if (currentState?.value.includes(PAST_BUG_PHONE)) {
    return {
      ...currentState,
      value: currentState.value.replace(PAST_BUG_PHONE, PHONE_TITLE),
    };
  }

  return nextState;
};